import React from "react";

export const LightIcon = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 12.2222C16.5978 12.2222 12.2222 16.5978 12.2222 22C12.2222 27.4022 16.5978 31.7778 22 31.7778C27.4022 31.7778 31.7778 27.4022 31.7778 22C31.7778 16.5978 27.4022 12.2222 22 12.2222ZM6.72222 23.8333C7.72444 23.8333 8.55556 23.0022 8.55556 22C8.55556 20.9978 7.72444 20.1667 6.72222 20.1667H1.83333C0.831111 20.1667 0 20.9978 0 22C0 23.0022 0.831111 23.8333 1.83333 23.8333H6.72222ZM42.1667 23.8333C43.1689 23.8333 44 23.0022 44 22C44 20.9978 43.1689 20.1667 42.1667 20.1667H37.2778C36.2756 20.1667 35.4444 20.9978 35.4444 22C35.4444 23.0022 36.2756 23.8333 37.2778 23.8333H42.1667ZM20.1667 6.72222C20.1667 7.72444 20.9978 8.55556 22 8.55556C23.0022 8.55556 23.8333 7.72444 23.8333 6.72222V1.83333C23.8333 0.831111 23.0022 0 22 0C20.9978 0 20.1667 0.831111 20.1667 1.83333V6.72222ZM31.5089 9.9C30.8 10.6089 30.8 11.7822 31.5089 12.4911C32.2178 13.2 33.3911 13.2 34.1 12.4911L36.6911 9.9C37.4 9.19111 37.4 8.01778 36.6911 7.30889C35.9822 6.6 34.8089 6.6 34.1 7.30889L31.5089 9.9ZM7.30889 34.1C6.6 34.8089 6.6 35.9822 7.30889 36.6911C8.01778 37.4 9.19111 37.4 9.9 36.6911L12.4911 34.1C13.2 33.3911 13.2 32.2178 12.4911 31.5089C11.7822 30.8 10.6089 30.8 9.9 31.5089L7.30889 34.1ZM9.9 12.4911C10.6089 13.2 11.7822 13.2 12.4911 12.4911C13.2 11.7822 13.2 10.6089 12.4911 9.9L9.9 7.30889C9.19111 6.6 8.01778 6.6 7.30889 7.30889C6.6 8.01778 6.6 9.19111 7.30889 9.9L9.9 12.4911ZM34.1 36.6911C34.8089 37.4 35.9822 37.4 36.6911 36.6911C37.4 35.9822 37.4 34.8089 36.6911 34.1L34.1 31.5089C33.3911 30.8 32.2178 30.8 31.5089 31.5089C30.8 32.2178 30.8 33.3911 31.5089 34.1L34.1 36.6911ZM20.1667 42.1667C20.1667 43.1689 20.9978 44 22 44C23.0022 44 23.8333 43.1689 23.8333 42.1667V37.2778C23.8333 36.2756 23.0022 35.4444 22 35.4444C20.9978 35.4444 20.1667 36.2756 20.1667 37.2778V42.1667Z"
        fill="#EE7267"
      />
    </svg>
  );
};

export const DarkIcon = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 6C13.1543 6 6 13.1543 6 22C6 30.8457 13.1543 38 22 38C30.8457 38 38 30.8457 38 22C38 21.1771 37.9314 20.3543 37.8171 19.5771C36.08 22 33.2229 23.6 30 23.6C24.6971 23.6 20.4 19.3029 20.4 14C20.4 10.7771 22 7.92 24.4229 6.18286C23.6457 6.06857 22.8229 6 22 6Z" fill="#CAC6FF" />
    </svg>
  );
};

export const HamburgerIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="hamburger-icon" d="M2 5 12 5 12 11 2 11Q-2 8 2 5M12 5 24 5 24 11 12 11M24 5 36 5 36 11 24 11M36 5 46 5Q50 8 46 11L36 11M2 21 12 21 12 27 2 27Q-2 24 2 21M12 21 24 21 24 27 12 27M24 21 36 21 36 27 24 27M36 21 46 21Q50 24 46 27L36 27M2 37 12 37 12 43 2 43Q-2 40 2 37M12 37 24 37 24 43 12 43M24 37 36 37 36 43 24 43M36 37 46 37Q50 40 46 43L36 43" fill="inherit" />
    </svg>
  );
};

export const Circle = (props) => {
  return (
    <svg id={props.id} className="blackhole" width="256" height="256" viewBox="-5 -5 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 30A1 1 0 0060 30 1 1 0 000 30Z" fill="white" />
    </svg>
  );
};
export const Arrow = (props) => {
  return (
    <svg id={props.id} width="60" height="50" viewBox="0 0 60 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30 0V45L25 45 30 50 35 45 30 45V0Z" fill="white" />
    </svg>
  );
};

export const ChipClose = (props) => {
  return (
    <svg id={props.id} className={props.class} width="48" height="48" viewBox="0 0 48 48" fill="inherit" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 33.6L24 26.1L31.5 33.6L33.6 31.5L26.1 24L33.6 16.5L31.5 14.4L24 21.9L16.5 14.4L14.4 16.5L21.9 24L14.4 31.5L16.5 33.6ZM24 44C21.1667 44 18.5333 43.4917 16.1 42.475C13.6667 41.4583 11.55 40.05 9.75 38.25C7.95 36.45 6.54167 34.3333 5.525 31.9C4.50833 29.4667 4 26.8333 4 24C4 21.2 4.50833 18.5833 5.525 16.15C6.54167 13.7167 7.95 11.6 9.75 9.8C11.55 8 13.6667 6.58333 16.1 5.55C18.5333 4.51667 21.1667 4 24 4C26.8 4 29.4167 4.51667 31.85 5.55C34.2833 6.58333 36.4 8 38.2 9.8C40 11.6 41.4167 13.7167 42.45 16.15C43.4833 18.5833 44 21.2 44 24C44 26.8333 43.4833 29.4667 42.45 31.9C41.4167 34.3333 40 36.45 38.2 38.25C36.4 40.05 34.2833 41.4583 31.85 42.475C29.4167 43.4917 26.8 44 24 44ZM24 41C28.6667 41 32.6667 39.3333 36 36C39.3333 32.6667 41 28.6667 41 24C41 19.3333 39.3333 15.3333 36 12C32.6667 8.66667 28.6667 7 24 7C19.3333 7 15.3333 8.66667 12 12C8.66667 15.3333 7 19.3333 7 24C7 28.6667 8.66667 32.6667 12 36C15.3333 39.3333 19.3333 41 24 41Z"
        fill="inherit" stroke="none"
      />
    </svg>
  );
};

export const Filter = (props) => {
  return (
    <svg id={props.id} className={props.class} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M41 11.5H7C6.17157 11.5 5.5 12.1716 5.5 13C5.5 13.8284 6.17157 14.5 7 14.5H41C41.8284 14.5 42.5 13.8284 42.5 13C42.5 12.1716 41.8284 11.5 41 11.5ZM35 21.5H13C12.1716 21.5 11.5 22.1716 11.5 23C11.5 23.8284 12.1716 24.5 13 24.5H35C35.8284 24.5 36.5 23.8284 36.5 23C36.5 22.1716 35.8284 21.5 35 21.5ZM27 32.5H21C20.1716 32.5 19.5 33.1716 19.5 34C19.5 34.8284 20.1716 35.5 21 35.5H27C27.8284 35.5 28.5 34.8284 28.5 34C28.5 33.1716 27.8284 32.5 27 32.5Z" fill="inherit" stroke="inherit" />
    </svg>
  );
};



export const ViewIcon = (props) => {
  return (
    <svg id={props.id} className={props.class} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="tiles" d="M14 7H10C7.23858 7 5 9.23858 5 12V16C5 18.7614 7.23858 21 10 21H14C16.7614 21 19 18.7614 19 16V12C19 9.23858 16.7614 7 14 7ZM14 27H10C7.23858 27 5 29.2386 5 32V36C5 38.7614 7.23858 41 10 41H14C16.7614 41 19 38.7614 19 36V32C19 29.2386 16.7614 27 14 27Z" stroke="inherit" strokeWidth="2" fill="none" />
      <path id="bottom-1" d="M25 19C25 18.448 25.448 18 26 18H40.998C41.552 18 42 18.448 42 19 42 19 42 19 42 19 42 19.552 41.552 20 41 20H26C25.448 20.001 25.001 19.554 25 19Z" fill="inherit" stroke="none" />
      <path id="bottom-2" d="M42 39C42 38.448 41.552 38 41 38H26C25.448 38 25 38.448 25 39V39C25 39.552 25.448 40 26 40H41c.552 0 1-.448 1-1Z" fill="inherit" stroke="none" />
      <path id="middle-1" d="M25 14C25 13.4477 25.4477 13 26 13H41C41.5523 13 42 13.4477 42 14C42 14.5523 41.5523 15 41 15H26C25.4477 15 25 14.5523 25 14Z" fill="inherit" stroke="none" />
      <path id="middle-2" d="M25 34C25 33.4477 25.4477 33 26 33H41C41.5523 33 42 33.4477 42 34C42 34.5523 41.5523 35 41 35H26C25.4477 35 25 34.5523 25 34Z" fill="inherit" stroke="none" />
      <path id="top-1" d="M25 9C25 8.44772 25.4477 8 26 8H41C41.5523 8 42 8.44772 42 9C42 9.55228 41.5523 10 41 10H26C25.4477 10 25 9.55228 25 9Z" fill="inherit" stroke="none" />
      <path id="top-2" d="M25 29C25 28.4477 25.4477 28 26 28H41C41.5523 28 42 28.4477 42 29C42 29.5523 41.5523 30 41 30H26C25.4477 30 25 29.5523 25 29Z" fill="inherit" stroke="none" />
    </svg>
  );
};

export const LongName = (props) => {
  return (
    <svg id={props.id} width="117" height="9" viewBox="-4 0 130 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* <path d="M0 0H1.26136L4.22727 7.24432H4.32955L7.29545 0H8.55682V8.72727H7.56818V2.09659H7.48295L4.75568 8.72727H3.80114L1.07386 2.09659H0.988636V8.72727H0V0Z" fill="black" />
      <path
        d="M13.1985 8.86364C12.6076 8.86364 12.0891 8.72301 11.6431 8.44176C11.1999 8.16051 10.8533 7.76705 10.6033 7.26136C10.3562 6.75568 10.2326 6.16477 10.2326 5.48864C10.2326 4.80682 10.3562 4.21165 10.6033 3.70312C10.8533 3.1946 11.1999 2.79972 11.6431 2.51847C12.0891 2.23722 12.6076 2.09659 13.1985 2.09659C13.7894 2.09659 14.3065 2.23722 14.7496 2.51847C15.1957 2.79972 15.5423 3.1946 15.7894 3.70312C16.0394 4.21165 16.1644 4.80682 16.1644 5.48864C16.1644 6.16477 16.0394 6.75568 15.7894 7.26136C15.5423 7.76705 15.1957 8.16051 14.7496 8.44176C14.3065 8.72301 13.7894 8.86364 13.1985 8.86364ZM13.1985 7.96023C13.6474 7.96023 14.0167 7.84517 14.3065 7.61506C14.5962 7.38494 14.8107 7.08239 14.9499 6.70739C15.0891 6.33239 15.1587 5.92614 15.1587 5.48864C15.1587 5.05114 15.0891 4.64347 14.9499 4.26562C14.8107 3.88778 14.5962 3.58239 14.3065 3.34943C14.0167 3.11648 13.6474 3 13.1985 3C12.7496 3 12.3803 3.11648 12.0906 3.34943C11.8008 3.58239 11.5863 3.88778 11.4471 4.26562C11.3079 4.64347 11.2383 5.05114 11.2383 5.48864C11.2383 5.92614 11.3079 6.33239 11.4471 6.70739C11.5863 7.08239 11.8008 7.38494 12.0906 7.61506C12.3803 7.84517 12.7496 7.96023 13.1985 7.96023Z"
        fill="black"
      />
      <path
        d="M18.7053 4.78977V8.72727H17.6996V0H18.7053V3.20455H18.7905C18.9439 2.86648 19.174 2.59801 19.4808 2.39915C19.7905 2.19744 20.2024 2.09659 20.7166 2.09659C21.1626 2.09659 21.5533 2.18608 21.8885 2.36506C22.2237 2.54119 22.4837 2.8125 22.6683 3.17898C22.8558 3.54261 22.9496 4.00568 22.9496 4.56818V8.72727H21.9439V4.63636C21.9439 4.11648 21.8089 3.71449 21.5391 3.4304C21.272 3.14347 20.9013 3 20.4268 3C20.0973 3 19.8018 3.0696 19.5405 3.20881C19.282 3.34801 19.0774 3.55114 18.9268 3.81818C18.7791 4.08523 18.7053 4.40909 18.7053 4.78977Z"
        fill="black"
      />
      <path
        d="M26.7156 8.88068C26.3008 8.88068 25.9244 8.80256 25.5863 8.64631C25.2482 8.48722 24.9798 8.25852 24.7809 7.96023C24.582 7.65909 24.4826 7.29545 24.4826 6.86932C24.4826 6.49432 24.5565 6.19034 24.7042 5.95739C24.8519 5.72159 25.0494 5.53693 25.2965 5.40341C25.5437 5.26989 25.8164 5.17045 26.1147 5.10511C26.4158 5.03693 26.7184 4.98295 27.0224 4.94318C27.4201 4.89205 27.7425 4.85369 27.9897 4.82812C28.2397 4.79972 28.4215 4.75284 28.5352 4.6875C28.6516 4.62216 28.7099 4.50852 28.7099 4.34659V4.3125C28.7099 3.89205 28.5948 3.56534 28.3647 3.33239C28.1374 3.09943 27.7923 2.98295 27.3292 2.98295C26.8491 2.98295 26.4727 3.08807 26.1999 3.2983C25.9272 3.50852 25.7354 3.73295 25.6246 3.97159L24.6701 3.63068C24.8406 3.23295 25.0678 2.9233 25.3519 2.7017C25.6388 2.47727 25.9513 2.32102 26.2894 2.23295C26.6303 2.14205 26.9656 2.09659 27.2951 2.09659C27.5053 2.09659 27.7468 2.12216 28.0195 2.1733C28.2951 2.22159 28.5607 2.32244 28.8164 2.47585C29.0749 2.62926 29.2894 2.8608 29.4599 3.17045C29.6303 3.48011 29.7156 3.89489 29.7156 4.41477V8.72727H28.7099V7.84091H28.6587C28.5906 7.98295 28.4769 8.13494 28.3178 8.29688C28.1587 8.45881 27.9471 8.59659 27.6829 8.71023C27.4187 8.82386 27.0962 8.88068 26.7156 8.88068ZM26.869 7.97727C27.2667 7.97727 27.6019 7.89915 27.8746 7.7429C28.1502 7.58665 28.3576 7.38494 28.4968 7.13778C28.6388 6.89063 28.7099 6.63068 28.7099 6.35795V5.4375C28.6673 5.48864 28.5735 5.53551 28.4286 5.57812C28.2866 5.6179 28.1218 5.65341 27.9343 5.68466C27.7496 5.71307 27.5692 5.73864 27.3931 5.76136C27.2198 5.78125 27.0792 5.7983 26.9712 5.8125C26.7099 5.84659 26.4656 5.90199 26.2383 5.97869C26.0138 6.05256 25.832 6.16477 25.6928 6.31534C25.5565 6.46307 25.4883 6.66477 25.4883 6.92045C25.4883 7.26989 25.6175 7.53409 25.8761 7.71307C26.1374 7.8892 26.4684 7.97727 26.869 7.97727Z"
        fill="black"
      />
      <path
        d="M31.5511 8.72727V2.18182H32.5227V3.20455H32.608C32.7443 2.85511 32.9645 2.58381 33.2685 2.39062C33.5724 2.1946 33.9375 2.09659 34.3636 2.09659C34.7955 2.09659 35.1548 2.1946 35.4418 2.39062C35.7315 2.58381 35.9574 2.85511 36.1193 3.20455H36.1875C36.3551 2.86648 36.6065 2.59801 36.9418 2.39915C37.277 2.19744 37.679 2.09659 38.1477 2.09659C38.733 2.09659 39.2116 2.27983 39.5838 2.64631C39.956 3.00994 40.142 3.5767 40.142 4.34659V8.72727H39.1364V4.34659C39.1364 3.86364 39.0043 3.51847 38.7401 3.31108C38.4759 3.10369 38.1648 3 37.8068 3C37.3466 3 36.9901 3.1392 36.7372 3.41761C36.4844 3.69318 36.358 4.04261 36.358 4.46591V8.72727H35.3352V4.24432C35.3352 3.87216 35.2145 3.57244 34.973 3.34517C34.7315 3.11506 34.4205 3 34.0398 3C33.7784 3 33.5341 3.0696 33.3068 3.20881C33.0824 3.34801 32.9006 3.54119 32.7614 3.78835C32.625 4.03267 32.5568 4.31534 32.5568 4.63636V8.72727H31.5511Z"
        fill="black"
      />
      <path
        d="M41.9808 8.72727V2.18182H42.9524V3.20455H43.0376C43.174 2.85511 43.3942 2.58381 43.6982 2.39062C44.0021 2.1946 44.3672 2.09659 44.7933 2.09659C45.2251 2.09659 45.5845 2.1946 45.8714 2.39062C46.1612 2.58381 46.3871 2.85511 46.549 3.20455H46.6172C46.7848 2.86648 47.0362 2.59801 47.3714 2.39915C47.7067 2.19744 48.1087 2.09659 48.5774 2.09659C49.1626 2.09659 49.6413 2.27983 50.0135 2.64631C50.3857 3.00994 50.5717 3.5767 50.5717 4.34659V8.72727H49.5661V4.34659C49.5661 3.86364 49.4339 3.51847 49.1697 3.31108C48.9055 3.10369 48.5945 3 48.2365 3C47.7763 3 47.4197 3.1392 47.1669 3.41761C46.9141 3.69318 46.7876 4.04261 46.7876 4.46591V8.72727H45.7649V4.24432C45.7649 3.87216 45.6442 3.57244 45.4027 3.34517C45.1612 3.11506 44.8501 3 44.4695 3C44.2081 3 43.9638 3.0696 43.7365 3.20881C43.5121 3.34801 43.3303 3.54119 43.1911 3.78835C43.0547 4.03267 42.9865 4.31534 42.9865 4.63636V8.72727H41.9808Z"
        fill="black"
      />
      <path
        d="M55.1548 8.86364C54.5241 8.86364 53.9801 8.72443 53.5227 8.44602C53.0682 8.16477 52.7173 7.77273 52.4702 7.26989C52.2259 6.7642 52.1037 6.17614 52.1037 5.50568C52.1037 4.83523 52.2259 4.24432 52.4702 3.73295C52.7173 3.21875 53.0611 2.81818 53.5014 2.53125C53.9446 2.24148 54.4616 2.09659 55.0526 2.09659C55.3935 2.09659 55.7301 2.15341 56.0625 2.26705C56.3949 2.38068 56.6974 2.56534 56.9702 2.82102C57.2429 3.07386 57.4602 3.40909 57.6222 3.8267C57.7841 4.24432 57.8651 4.75852 57.8651 5.36932V5.79545H52.8196V4.92614H56.8423C56.8423 4.55682 56.7685 4.22727 56.6207 3.9375C56.4759 3.64773 56.2685 3.41903 55.9986 3.25142C55.7315 3.08381 55.4162 3 55.0526 3C54.652 3 54.3054 3.09943 54.0128 3.2983C53.723 3.49432 53.5 3.75 53.3438 4.06534C53.1875 4.38068 53.1094 4.71875 53.1094 5.07955V5.65909C53.1094 6.15341 53.1946 6.57244 53.3651 6.91619C53.5384 7.2571 53.7784 7.51705 54.0852 7.69602C54.392 7.87216 54.7486 7.96023 55.1548 7.96023C55.419 7.96023 55.6577 7.9233 55.8707 7.84943C56.0866 7.77273 56.2727 7.65909 56.429 7.50852C56.5852 7.35511 56.706 7.16477 56.7912 6.9375L57.7628 7.21023C57.6605 7.53977 57.4886 7.82955 57.2472 8.07955C57.0057 8.32671 56.7074 8.51989 56.3523 8.65909C55.9972 8.79545 55.598 8.86364 55.1548 8.86364Z"
        fill="black"
      />
      <path
        d="M61.8665 8.86364C61.321 8.86364 60.8395 8.72585 60.4219 8.45028C60.0043 8.17187 59.6776 7.77983 59.4418 7.27415C59.206 6.76563 59.0881 6.16477 59.0881 5.47159C59.0881 4.78409 59.206 4.1875 59.4418 3.68182C59.6776 3.17614 60.0057 2.78551 60.4261 2.50994C60.8466 2.23438 61.3324 2.09659 61.8835 2.09659C62.3097 2.09659 62.6463 2.16761 62.8935 2.30966C63.1435 2.44886 63.3338 2.60795 63.4645 2.78693C63.598 2.96307 63.7017 3.10795 63.7756 3.22159H63.8608V0H64.8665V8.72727H63.8949V7.72159H63.7756C63.7017 7.84091 63.5966 7.99148 63.4602 8.1733C63.3239 8.35227 63.1293 8.51278 62.8764 8.65483C62.6236 8.79403 62.2869 8.86364 61.8665 8.86364ZM62.0028 7.96023C62.4063 7.96023 62.7472 7.85511 63.0256 7.64489C63.304 7.43182 63.5156 7.13778 63.6605 6.76278C63.8054 6.38494 63.8778 5.94886 63.8778 5.45455C63.8778 4.96591 63.8068 4.53835 63.6648 4.17188C63.5227 3.80256 63.3125 3.51563 63.0341 3.31108C62.7557 3.10369 62.4119 3 62.0028 3C61.5767 3 61.2216 3.10938 60.9375 3.32812C60.6562 3.54403 60.4446 3.83807 60.3026 4.21023C60.1634 4.57955 60.0938 4.99432 60.0938 5.45455C60.0938 5.92045 60.1648 6.34375 60.3068 6.72443C60.4517 7.10227 60.6648 7.40341 60.946 7.62784C61.2301 7.84943 61.5824 7.96023 62.0028 7.96023Z"
        fill="black"
      />
      <path d="M70.0696 8.72727V7.96023L74.7741 0.9375H70.0185V0H76.0355V0.767045L71.331 7.78977H76.0866V8.72727H70.0696Z" fill="black" />
      <path
        d="M80.4673 8.86364C79.8366 8.86364 79.2926 8.72443 78.8352 8.44602C78.3807 8.16477 78.0298 7.77273 77.7827 7.26989C77.5384 6.7642 77.4162 6.17614 77.4162 5.50568C77.4162 4.83523 77.5384 4.24432 77.7827 3.73295C78.0298 3.21875 78.3736 2.81818 78.8139 2.53125C79.2571 2.24148 79.7741 2.09659 80.3651 2.09659C80.706 2.09659 81.0426 2.15341 81.375 2.26705C81.7074 2.38068 82.0099 2.56534 82.2827 2.82102C82.5554 3.07386 82.7727 3.40909 82.9347 3.8267C83.0966 4.24432 83.1776 4.75852 83.1776 5.36932V5.79545H78.1321V4.92614H82.1548C82.1548 4.55682 82.081 4.22727 81.9332 3.9375C81.7883 3.64773 81.581 3.41903 81.3111 3.25142C81.044 3.08381 80.7287 3 80.3651 3C79.9645 3 79.6179 3.09943 79.3253 3.2983C79.0355 3.49432 78.8125 3.75 78.6562 4.06534C78.5 4.38068 78.4219 4.71875 78.4219 5.07955V5.65909C78.4219 6.15341 78.5071 6.57244 78.6776 6.91619C78.8509 7.2571 79.0909 7.51705 79.3977 7.69602C79.7045 7.87216 80.0611 7.96023 80.4673 7.96023C80.7315 7.96023 80.9702 7.9233 81.1832 7.84943C81.3991 7.77273 81.5852 7.65909 81.7415 7.50852C81.8977 7.35511 82.0185 7.16477 82.1037 6.9375L83.0753 7.21023C82.973 7.53977 82.8011 7.82955 82.5597 8.07955C82.3182 8.32671 82.0199 8.51989 81.6648 8.65909C81.3097 8.79545 80.9105 8.86364 80.4673 8.86364Z"
        fill="black"
      />
      <path
        d="M87.4517 8.86364C86.821 8.86364 86.277 8.72443 85.8196 8.44602C85.3651 8.16477 85.0142 7.77273 84.767 7.26989C84.5227 6.7642 84.4006 6.17614 84.4006 5.50568C84.4006 4.83523 84.5227 4.24432 84.767 3.73295C85.0142 3.21875 85.358 2.81818 85.7983 2.53125C86.2415 2.24148 86.7585 2.09659 87.3494 2.09659C87.6903 2.09659 88.027 2.15341 88.3594 2.26705C88.6918 2.38068 88.9943 2.56534 89.267 2.82102C89.5398 3.07386 89.7571 3.40909 89.919 3.8267C90.081 4.24432 90.1619 4.75852 90.1619 5.36932V5.79545H85.1165V4.92614H89.1392C89.1392 4.55682 89.0653 4.22727 88.9176 3.9375C88.7727 3.64773 88.5653 3.41903 88.2955 3.25142C88.0284 3.08381 87.7131 3 87.3494 3C86.9489 3 86.6023 3.09943 86.3097 3.2983C86.0199 3.49432 85.7969 3.75 85.6406 4.06534C85.4844 4.38068 85.4062 4.71875 85.4062 5.07955V5.65909C85.4062 6.15341 85.4915 6.57244 85.6619 6.91619C85.8352 7.2571 86.0753 7.51705 86.3821 7.69602C86.6889 7.87216 87.0455 7.96023 87.4517 7.96023C87.7159 7.96023 87.9545 7.9233 88.1676 7.84943C88.3835 7.77273 88.5696 7.65909 88.7259 7.50852C88.8821 7.35511 89.0028 7.16477 89.0881 6.9375L90.0597 7.21023C89.9574 7.53977 89.7855 7.82955 89.544 8.07955C89.3026 8.32671 89.0043 8.51989 88.6491 8.65909C88.294 8.79545 87.8949 8.86364 87.4517 8.86364Z"
        fill="black"
      />
      <path
        d="M96.3281 3.64773L95.4247 3.90341C95.3679 3.75284 95.2841 3.60653 95.1733 3.46449C95.0653 3.3196 94.9176 3.20028 94.7301 3.10653C94.5426 3.01278 94.3026 2.96591 94.0099 2.96591C93.6094 2.96591 93.2756 3.05824 93.0085 3.2429C92.7443 3.42472 92.6122 3.65625 92.6122 3.9375C92.6122 4.1875 92.7031 4.38494 92.8849 4.52983C93.0668 4.67472 93.3508 4.79545 93.7372 4.89205L94.7088 5.13068C95.294 5.27273 95.7301 5.49006 96.017 5.78267C96.304 6.07244 96.4474 6.44602 96.4474 6.90341C96.4474 7.27841 96.3395 7.61364 96.1236 7.90909C95.9105 8.20455 95.6122 8.4375 95.2287 8.60795C94.8452 8.77841 94.3991 8.86364 93.8906 8.86364C93.223 8.86364 92.6705 8.71875 92.233 8.42898C91.7955 8.13921 91.5185 7.71591 91.402 7.15909L92.3565 6.92045C92.4474 7.27273 92.6193 7.53693 92.8722 7.71307C93.1278 7.8892 93.4616 7.97727 93.8736 7.97727C94.3423 7.97727 94.7145 7.87784 94.9901 7.67898C95.2685 7.47727 95.4077 7.2358 95.4077 6.95455C95.4077 6.72727 95.3281 6.53693 95.169 6.38352C95.0099 6.22727 94.7656 6.1108 94.4361 6.03409L93.3452 5.77841C92.7457 5.63636 92.3054 5.41619 92.0241 5.1179C91.7457 4.81676 91.6065 4.44034 91.6065 3.98864C91.6065 3.61932 91.7102 3.29261 91.9176 3.00852C92.1278 2.72443 92.4134 2.50142 92.7741 2.33949C93.1378 2.17756 93.5497 2.09659 94.0099 2.09659C94.6577 2.09659 95.1662 2.23864 95.5355 2.52273C95.9077 2.80682 96.1719 3.18182 96.3281 3.64773Z"
        fill="black"
      />
      <path
        d="M98.967 4.78977V8.72727H97.9613V0H98.967V3.20455H99.0522C99.2056 2.86648 99.4357 2.59801 99.7425 2.39915C100.052 2.19744 100.464 2.09659 100.978 2.09659C101.424 2.09659 101.815 2.18608 102.15 2.36506C102.485 2.54119 102.745 2.8125 102.93 3.17898C103.118 3.54261 103.211 4.00568 103.211 4.56818V8.72727H102.206V4.63636C102.206 4.11648 102.071 3.71449 101.801 3.4304C101.534 3.14347 101.163 3 100.689 3C100.359 3 100.064 3.0696 99.8022 3.20881C99.5437 3.34801 99.3391 3.55114 99.1886 3.81818C99.0408 4.08523 98.967 4.40909 98.967 4.78977Z"
        fill="black"
      />
      <path
        d="M106.977 8.88068C106.562 8.88068 106.186 8.80256 105.848 8.64631C105.51 8.48722 105.241 8.25852 105.043 7.96023C104.844 7.65909 104.744 7.29545 104.744 6.86932C104.744 6.49432 104.818 6.19034 104.966 5.95739C105.114 5.72159 105.311 5.53693 105.558 5.40341C105.805 5.26989 106.078 5.17045 106.376 5.10511C106.678 5.03693 106.98 4.98295 107.284 4.94318C107.682 4.89205 108.004 4.85369 108.251 4.82812C108.501 4.79972 108.683 4.75284 108.797 4.6875C108.913 4.62216 108.972 4.50852 108.972 4.34659V4.3125C108.972 3.89205 108.857 3.56534 108.626 3.33239C108.399 3.09943 108.054 2.98295 107.591 2.98295C107.111 2.98295 106.734 3.08807 106.462 3.2983C106.189 3.50852 105.997 3.73295 105.886 3.97159L104.932 3.63068C105.102 3.23295 105.33 2.9233 105.614 2.7017C105.901 2.47727 106.213 2.32102 106.551 2.23295C106.892 2.14205 107.227 2.09659 107.557 2.09659C107.767 2.09659 108.009 2.12216 108.281 2.1733C108.557 2.22159 108.822 2.32244 109.078 2.47585C109.337 2.62926 109.551 2.8608 109.722 3.17045C109.892 3.48011 109.977 3.89489 109.977 4.41477V8.72727H108.972V7.84091H108.92C108.852 7.98295 108.739 8.13494 108.58 8.29688C108.42 8.45881 108.209 8.59659 107.945 8.71023C107.68 8.82386 107.358 8.88068 106.977 8.88068ZM107.131 7.97727C107.528 7.97727 107.864 7.89915 108.136 7.7429C108.412 7.58665 108.619 7.38494 108.759 7.13778C108.901 6.89063 108.972 6.63068 108.972 6.35795V5.4375C108.929 5.48864 108.835 5.53551 108.69 5.57812C108.548 5.6179 108.384 5.65341 108.196 5.68466C108.011 5.71307 107.831 5.73864 107.655 5.76136C107.482 5.78125 107.341 5.7983 107.233 5.8125C106.972 5.84659 106.727 5.90199 106.5 5.97869C106.276 6.05256 106.094 6.16477 105.955 6.31534C105.818 6.46307 105.75 6.66477 105.75 6.92045C105.75 7.26989 105.879 7.53409 106.138 7.71307C106.399 7.8892 106.73 7.97727 107.131 7.97727Z"
        fill="black"
      />
      <path
        d="M112.819 4.78977V8.72727H111.813V2.18182H112.784V3.20455H112.87C113.023 2.87216 113.256 2.60511 113.569 2.40341C113.881 2.19886 114.284 2.09659 114.779 2.09659C115.222 2.09659 115.61 2.1875 115.942 2.36932C116.275 2.5483 116.533 2.82102 116.718 3.1875C116.902 3.55114 116.995 4.01136 116.995 4.56818V8.72727H115.989V4.63636C115.989 4.12216 115.855 3.72159 115.588 3.43466C115.321 3.14489 114.955 3 114.489 3C114.168 3 113.881 3.0696 113.628 3.20881C113.378 3.34801 113.181 3.55114 113.036 3.81818C112.891 4.08523 112.819 4.40909 112.819 4.78977Z"
        fill="black"
      /> */}

      <path
        d="M0 0H1.26136L4.22727 7.24432H4.32955L7.29545 0H8.55682V8.72727H7.56818V2.09659H7.48295L4.75568 8.72727H3.80114L1.07386 2.09659H0.988636V8.72727H0V0Z

M13.1985 8.86364C12.6076 8.86364 12.0891 8.72301 11.6431 8.44176C11.1999 8.16051 10.8533 7.76705 10.6033 7.26136C10.3562 6.75568 10.2326 6.16477 10.2326 5.48864C10.2326 4.80682 10.3562 4.21165 10.6033 3.70312C10.8533 3.1946 11.1999 2.79972 11.6431 2.51847C12.0891 2.23722 12.6076 2.09659 13.1985 2.09659C13.7894 2.09659 14.3065 2.23722 14.7496 2.51847C15.1957 2.79972 15.5423 3.1946 15.7894 3.70312C16.0394 4.21165 16.1644 4.80682 16.1644 5.48864C16.1644 6.16477 16.0394 6.75568 15.7894 7.26136C15.5423 7.76705 15.1957 8.16051 14.7496 8.44176C14.3065 8.72301 13.7894 8.86364 13.1985 8.86364ZM13.1985 7.96023C13.6474 7.96023 14.0167 7.84517 14.3065 7.61506C14.5962 7.38494 14.8107 7.08239 14.9499 6.70739C15.0891 6.33239 15.1587 5.92614 15.1587 5.48864C15.1587 5.05114 15.0891 4.64347 14.9499 4.26562C14.8107 3.88778 14.5962 3.58239 14.3065 3.34943C14.0167 3.11648 13.6474 3 13.1985 3C12.7496 3 12.3803 3.11648 12.0906 3.34943C11.8008 3.58239 11.5863 3.88778 11.4471 4.26562C11.3079 4.64347 11.2383 5.05114 11.2383 5.48864C11.2383 5.92614 11.3079 6.33239 11.4471 6.70739C11.5863 7.08239 11.8008 7.38494 12.0906 7.61506C12.3803 7.84517 12.7496 7.96023 13.1985 7.96023Z

M18.7053 4.78977V8.72727H17.6996V0H18.7053V3.20455H18.7905C18.9439 2.86648 19.174 2.59801 19.4808 2.39915C19.7905 2.19744 20.2024 2.09659 20.7166 2.09659C21.1626 2.09659 21.5533 2.18608 21.8885 2.36506C22.2237 2.54119 22.4837 2.8125 22.6683 3.17898C22.8558 3.54261 22.9496 4.00568 22.9496 4.56818V8.72727H21.9439V4.63636C21.9439 4.11648 21.8089 3.71449 21.5391 3.4304C21.272 3.14347 20.9013 3 20.4268 3C20.0973 3 19.8018 3.0696 19.5405 3.20881C19.282 3.34801 19.0774 3.55114 18.9268 3.81818C18.7791 4.08523 18.7053 4.40909 18.7053 4.78977Z

M26.7156 8.88068C26.3008 8.88068 25.9244 8.80256 25.5863 8.64631C25.2482 8.48722 24.9798 8.25852 24.7809 7.96023C24.582 7.65909 24.4826 7.29545 24.4826 6.86932C24.4826 6.49432 24.5565 6.19034 24.7042 5.95739C24.8519 5.72159 25.0494 5.53693 25.2965 5.40341C25.5437 5.26989 25.8164 5.17045 26.1147 5.10511C26.4158 5.03693 26.7184 4.98295 27.0224 4.94318C27.4201 4.89205 27.7425 4.85369 27.9897 4.82812C28.2397 4.79972 28.4215 4.75284 28.5352 4.6875C28.6516 4.62216 28.7099 4.50852 28.7099 4.34659V4.3125C28.7099 3.89205 28.5948 3.56534 28.3647 3.33239C28.1374 3.09943 27.7923 2.98295 27.3292 2.98295C26.8491 2.98295 26.4727 3.08807 26.1999 3.2983C25.9272 3.50852 25.7354 3.73295 25.6246 3.97159L24.6701 3.63068C24.8406 3.23295 25.0678 2.9233 25.3519 2.7017C25.6388 2.47727 25.9513 2.32102 26.2894 2.23295C26.6303 2.14205 26.9656 2.09659 27.2951 2.09659C27.5053 2.09659 27.7468 2.12216 28.0195 2.1733C28.2951 2.22159 28.5607 2.32244 28.8164 2.47585C29.0749 2.62926 29.2894 2.8608 29.4599 3.17045C29.6303 3.48011 29.7156 3.89489 29.7156 4.41477V8.72727H28.7099V7.84091H28.6587C28.5906 7.98295 28.4769 8.13494 28.3178 8.29688C28.1587 8.45881 27.9471 8.59659 27.6829 8.71023C27.4187 8.82386 27.0962 8.88068 26.7156 8.88068ZM26.869 7.97727C27.2667 7.97727 27.6019 7.89915 27.8746 7.7429C28.1502 7.58665 28.3576 7.38494 28.4968 7.13778C28.6388 6.89063 28.7099 6.63068 28.7099 6.35795V5.4375C28.6673 5.48864 28.5735 5.53551 28.4286 5.57812C28.2866 5.6179 28.1218 5.65341 27.9343 5.68466C27.7496 5.71307 27.5692 5.73864 27.3931 5.76136C27.2198 5.78125 27.0792 5.7983 26.9712 5.8125C26.7099 5.84659 26.4656 5.90199 26.2383 5.97869C26.0138 6.05256 25.832 6.16477 25.6928 6.31534C25.5565 6.46307 25.4883 6.66477 25.4883 6.92045C25.4883 7.26989 25.6175 7.53409 25.8761 7.71307C26.1374 7.8892 26.4684 7.97727 26.869 7.97727Z

M31.5511 8.72727V2.18182H32.5227V3.20455H32.608C32.7443 2.85511 32.9645 2.58381 33.2685 2.39062C33.5724 2.1946 33.9375 2.09659 34.3636 2.09659C34.7955 2.09659 35.1548 2.1946 35.4418 2.39062C35.7315 2.58381 35.9574 2.85511 36.1193 3.20455H36.1875C36.3551 2.86648 36.6065 2.59801 36.9418 2.39915C37.277 2.19744 37.679 2.09659 38.1477 2.09659C38.733 2.09659 39.2116 2.27983 39.5838 2.64631C39.956 3.00994 40.142 3.5767 40.142 4.34659V8.72727H39.1364V4.34659C39.1364 3.86364 39.0043 3.51847 38.7401 3.31108C38.4759 3.10369 38.1648 3 37.8068 3C37.3466 3 36.9901 3.1392 36.7372 3.41761C36.4844 3.69318 36.358 4.04261 36.358 4.46591V8.72727H35.3352V4.24432C35.3352 3.87216 35.2145 3.57244 34.973 3.34517C34.7315 3.11506 34.4205 3 34.0398 3C33.7784 3 33.5341 3.0696 33.3068 3.20881C33.0824 3.34801 32.9006 3.54119 32.7614 3.78835C32.625 4.03267 32.5568 4.31534 32.5568 4.63636V8.72727H31.5511Z

M41.9808 8.72727V2.18182H42.9524V3.20455H43.0376C43.174 2.85511 43.3942 2.58381 43.6982 2.39062C44.0021 2.1946 44.3672 2.09659 44.7933 2.09659C45.2251 2.09659 45.5845 2.1946 45.8714 2.39062C46.1612 2.58381 46.3871 2.85511 46.549 3.20455H46.6172C46.7848 2.86648 47.0362 2.59801 47.3714 2.39915C47.7067 2.19744 48.1087 2.09659 48.5774 2.09659C49.1626 2.09659 49.6413 2.27983 50.0135 2.64631C50.3857 3.00994 50.5717 3.5767 50.5717 4.34659V8.72727H49.5661V4.34659C49.5661 3.86364 49.4339 3.51847 49.1697 3.31108C48.9055 3.10369 48.5945 3 48.2365 3C47.7763 3 47.4197 3.1392 47.1669 3.41761C46.9141 3.69318 46.7876 4.04261 46.7876 4.46591V8.72727H45.7649V4.24432C45.7649 3.87216 45.6442 3.57244 45.4027 3.34517C45.1612 3.11506 44.8501 3 44.4695 3C44.2081 3 43.9638 3.0696 43.7365 3.20881C43.5121 3.34801 43.3303 3.54119 43.1911 3.78835C43.0547 4.03267 42.9865 4.31534 42.9865 4.63636V8.72727H41.9808Z

M55.1548 8.86364C54.5241 8.86364 53.9801 8.72443 53.5227 8.44602C53.0682 8.16477 52.7173 7.77273 52.4702 7.26989C52.2259 6.7642 52.1037 6.17614 52.1037 5.50568C52.1037 4.83523 52.2259 4.24432 52.4702 3.73295C52.7173 3.21875 53.0611 2.81818 53.5014 2.53125C53.9446 2.24148 54.4616 2.09659 55.0526 2.09659C55.3935 2.09659 55.7301 2.15341 56.0625 2.26705C56.3949 2.38068 56.6974 2.56534 56.9702 2.82102C57.2429 3.07386 57.4602 3.40909 57.6222 3.8267C57.7841 4.24432 57.8651 4.75852 57.8651 5.36932V5.79545H52.8196V4.92614H56.8423C56.8423 4.55682 56.7685 4.22727 56.6207 3.9375C56.4759 3.64773 56.2685 3.41903 55.9986 3.25142C55.7315 3.08381 55.4162 3 55.0526 3C54.652 3 54.3054 3.09943 54.0128 3.2983C53.723 3.49432 53.5 3.75 53.3438 4.06534C53.1875 4.38068 53.1094 4.71875 53.1094 5.07955V5.65909C53.1094 6.15341 53.1946 6.57244 53.3651 6.91619C53.5384 7.2571 53.7784 7.51705 54.0852 7.69602C54.392 7.87216 54.7486 7.96023 55.1548 7.96023C55.419 7.96023 55.6577 7.9233 55.8707 7.84943C56.0866 7.77273 56.2727 7.65909 56.429 7.50852C56.5852 7.35511 56.706 7.16477 56.7912 6.9375L57.7628 7.21023C57.6605 7.53977 57.4886 7.82955 57.2472 8.07955C57.0057 8.32671 56.7074 8.51989 56.3523 8.65909C55.9972 8.79545 55.598 8.86364 55.1548 8.86364Z

M61.8665 8.86364C61.321 8.86364 60.8395 8.72585 60.4219 8.45028C60.0043 8.17187 59.6776 7.77983 59.4418 7.27415C59.206 6.76563 59.0881 6.16477 59.0881 5.47159C59.0881 4.78409 59.206 4.1875 59.4418 3.68182C59.6776 3.17614 60.0057 2.78551 60.4261 2.50994C60.8466 2.23438 61.3324 2.09659 61.8835 2.09659C62.3097 2.09659 62.6463 2.16761 62.8935 2.30966C63.1435 2.44886 63.3338 2.60795 63.4645 2.78693C63.598 2.96307 63.7017 3.10795 63.7756 3.22159H63.8608V0H64.8665V8.72727H63.8949V7.72159H63.7756C63.7017 7.84091 63.5966 7.99148 63.4602 8.1733C63.3239 8.35227 63.1293 8.51278 62.8764 8.65483C62.6236 8.79403 62.2869 8.86364 61.8665 8.86364ZM62.0028 7.96023C62.4063 7.96023 62.7472 7.85511 63.0256 7.64489C63.304 7.43182 63.5156 7.13778 63.6605 6.76278C63.8054 6.38494 63.8778 5.94886 63.8778 5.45455C63.8778 4.96591 63.8068 4.53835 63.6648 4.17188C63.5227 3.80256 63.3125 3.51563 63.0341 3.31108C62.7557 3.10369 62.4119 3 62.0028 3C61.5767 3 61.2216 3.10938 60.9375 3.32812C60.6562 3.54403 60.4446 3.83807 60.3026 4.21023C60.1634 4.57955 60.0938 4.99432 60.0938 5.45455C60.0938 5.92045 60.1648 6.34375 60.3068 6.72443C60.4517 7.10227 60.6648 7.40341 60.946 7.62784C61.2301 7.84943 61.5824 7.96023 62.0028 7.96023Z

M70.0696 8.72727V7.96023L74.7741 0.9375H70.0185V0H76.0355V0.767045L71.331 7.78977H76.0866V8.72727H70.0696Z

M80.4673 8.86364C79.8366 8.86364 79.2926 8.72443 78.8352 8.44602C78.3807 8.16477 78.0298 7.77273 77.7827 7.26989C77.5384 6.7642 77.4162 6.17614 77.4162 5.50568C77.4162 4.83523 77.5384 4.24432 77.7827 3.73295C78.0298 3.21875 78.3736 2.81818 78.8139 2.53125C79.2571 2.24148 79.7741 2.09659 80.3651 2.09659C80.706 2.09659 81.0426 2.15341 81.375 2.26705C81.7074 2.38068 82.0099 2.56534 82.2827 2.82102C82.5554 3.07386 82.7727 3.40909 82.9347 3.8267C83.0966 4.24432 83.1776 4.75852 83.1776 5.36932V5.79545H78.1321V4.92614H82.1548C82.1548 4.55682 82.081 4.22727 81.9332 3.9375C81.7883 3.64773 81.581 3.41903 81.3111 3.25142C81.044 3.08381 80.7287 3 80.3651 3C79.9645 3 79.6179 3.09943 79.3253 3.2983C79.0355 3.49432 78.8125 3.75 78.6562 4.06534C78.5 4.38068 78.4219 4.71875 78.4219 5.07955V5.65909C78.4219 6.15341 78.5071 6.57244 78.6776 6.91619C78.8509 7.2571 79.0909 7.51705 79.3977 7.69602C79.7045 7.87216 80.0611 7.96023 80.4673 7.96023C80.7315 7.96023 80.9702 7.9233 81.1832 7.84943C81.3991 7.77273 81.5852 7.65909 81.7415 7.50852C81.8977 7.35511 82.0185 7.16477 82.1037 6.9375L83.0753 7.21023C82.973 7.53977 82.8011 7.82955 82.5597 8.07955C82.3182 8.32671 82.0199 8.51989 81.6648 8.65909C81.3097 8.79545 80.9105 8.86364 80.4673 8.86364Z

M87.4517 8.86364C86.821 8.86364 86.277 8.72443 85.8196 8.44602C85.3651 8.16477 85.0142 7.77273 84.767 7.26989C84.5227 6.7642 84.4006 6.17614 84.4006 5.50568C84.4006 4.83523 84.5227 4.24432 84.767 3.73295C85.0142 3.21875 85.358 2.81818 85.7983 2.53125C86.2415 2.24148 86.7585 2.09659 87.3494 2.09659C87.6903 2.09659 88.027 2.15341 88.3594 2.26705C88.6918 2.38068 88.9943 2.56534 89.267 2.82102C89.5398 3.07386 89.7571 3.40909 89.919 3.8267C90.081 4.24432 90.1619 4.75852 90.1619 5.36932V5.79545H85.1165V4.92614H89.1392C89.1392 4.55682 89.0653 4.22727 88.9176 3.9375C88.7727 3.64773 88.5653 3.41903 88.2955 3.25142C88.0284 3.08381 87.7131 3 87.3494 3C86.9489 3 86.6023 3.09943 86.3097 3.2983C86.0199 3.49432 85.7969 3.75 85.6406 4.06534C85.4844 4.38068 85.4062 4.71875 85.4062 5.07955V5.65909C85.4062 6.15341 85.4915 6.57244 85.6619 6.91619C85.8352 7.2571 86.0753 7.51705 86.3821 7.69602C86.6889 7.87216 87.0455 7.96023 87.4517 7.96023C87.7159 7.96023 87.9545 7.9233 88.1676 7.84943C88.3835 7.77273 88.5696 7.65909 88.7259 7.50852C88.8821 7.35511 89.0028 7.16477 89.0881 6.9375L90.0597 7.21023C89.9574 7.53977 89.7855 7.82955 89.544 8.07955C89.3026 8.32671 89.0043 8.51989 88.6491 8.65909C88.294 8.79545 87.8949 8.86364 87.4517 8.86364Z

M96.3281 3.64773L95.4247 3.90341C95.3679 3.75284 95.2841 3.60653 95.1733 3.46449C95.0653 3.3196 94.9176 3.20028 94.7301 3.10653C94.5426 3.01278 94.3026 2.96591 94.0099 2.96591C93.6094 2.96591 93.2756 3.05824 93.0085 3.2429C92.7443 3.42472 92.6122 3.65625 92.6122 3.9375C92.6122 4.1875 92.7031 4.38494 92.8849 4.52983C93.0668 4.67472 93.3508 4.79545 93.7372 4.89205L94.7088 5.13068C95.294 5.27273 95.7301 5.49006 96.017 5.78267C96.304 6.07244 96.4474 6.44602 96.4474 6.90341C96.4474 7.27841 96.3395 7.61364 96.1236 7.90909C95.9105 8.20455 95.6122 8.4375 95.2287 8.60795C94.8452 8.77841 94.3991 8.86364 93.8906 8.86364C93.223 8.86364 92.6705 8.71875 92.233 8.42898C91.7955 8.13921 91.5185 7.71591 91.402 7.15909L92.3565 6.92045C92.4474 7.27273 92.6193 7.53693 92.8722 7.71307C93.1278 7.8892 93.4616 7.97727 93.8736 7.97727C94.3423 7.97727 94.7145 7.87784 94.9901 7.67898C95.2685 7.47727 95.4077 7.2358 95.4077 6.95455C95.4077 6.72727 95.3281 6.53693 95.169 6.38352C95.0099 6.22727 94.7656 6.1108 94.4361 6.03409L93.3452 5.77841C92.7457 5.63636 92.3054 5.41619 92.0241 5.1179C91.7457 4.81676 91.6065 4.44034 91.6065 3.98864C91.6065 3.61932 91.7102 3.29261 91.9176 3.00852C92.1278 2.72443 92.4134 2.50142 92.7741 2.33949C93.1378 2.17756 93.5497 2.09659 94.0099 2.09659C94.6577 2.09659 95.1662 2.23864 95.5355 2.52273C95.9077 2.80682 96.1719 3.18182 96.3281 3.64773Z

M98.967 4.78977V8.72727H97.9613V0H98.967V3.20455H99.0522C99.2056 2.86648 99.4357 2.59801 99.7425 2.39915C100.052 2.19744 100.464 2.09659 100.978 2.09659C101.424 2.09659 101.815 2.18608 102.15 2.36506C102.485 2.54119 102.745 2.8125 102.93 3.17898C103.118 3.54261 103.211 4.00568 103.211 4.56818V8.72727H102.206V4.63636C102.206 4.11648 102.071 3.71449 101.801 3.4304C101.534 3.14347 101.163 3 100.689 3C100.359 3 100.064 3.0696 99.8022 3.20881C99.5437 3.34801 99.3391 3.55114 99.1886 3.81818C99.0408 4.08523 98.967 4.40909 98.967 4.78977Z

M106.977 8.88068C106.562 8.88068 106.186 8.80256 105.848 8.64631C105.51 8.48722 105.241 8.25852 105.043 7.96023C104.844 7.65909 104.744 7.29545 104.744 6.86932C104.744 6.49432 104.818 6.19034 104.966 5.95739C105.114 5.72159 105.311 5.53693 105.558 5.40341C105.805 5.26989 106.078 5.17045 106.376 5.10511C106.678 5.03693 106.98 4.98295 107.284 4.94318C107.682 4.89205 108.004 4.85369 108.251 4.82812C108.501 4.79972 108.683 4.75284 108.797 4.6875C108.913 4.62216 108.972 4.50852 108.972 4.34659V4.3125C108.972 3.89205 108.857 3.56534 108.626 3.33239C108.399 3.09943 108.054 2.98295 107.591 2.98295C107.111 2.98295 106.734 3.08807 106.462 3.2983C106.189 3.50852 105.997 3.73295 105.886 3.97159L104.932 3.63068C105.102 3.23295 105.33 2.9233 105.614 2.7017C105.901 2.47727 106.213 2.32102 106.551 2.23295C106.892 2.14205 107.227 2.09659 107.557 2.09659C107.767 2.09659 108.009 2.12216 108.281 2.1733C108.557 2.22159 108.822 2.32244 109.078 2.47585C109.337 2.62926 109.551 2.8608 109.722 3.17045C109.892 3.48011 109.977 3.89489 109.977 4.41477V8.72727H108.972V7.84091H108.92C108.852 7.98295 108.739 8.13494 108.58 8.29688C108.42 8.45881 108.209 8.59659 107.945 8.71023C107.68 8.82386 107.358 8.88068 106.977 8.88068ZM107.131 7.97727C107.528 7.97727 107.864 7.89915 108.136 7.7429C108.412 7.58665 108.619 7.38494 108.759 7.13778C108.901 6.89063 108.972 6.63068 108.972 6.35795V5.4375C108.929 5.48864 108.835 5.53551 108.69 5.57812C108.548 5.6179 108.384 5.65341 108.196 5.68466C108.011 5.71307 107.831 5.73864 107.655 5.76136C107.482 5.78125 107.341 5.7983 107.233 5.8125C106.972 5.84659 106.727 5.90199 106.5 5.97869C106.276 6.05256 106.094 6.16477 105.955 6.31534C105.818 6.46307 105.75 6.66477 105.75 6.92045C105.75 7.26989 105.879 7.53409 106.138 7.71307C106.399 7.8892 106.73 7.97727 107.131 7.97727Z

M112.819 4.78977V8.72727H111.813V2.18182H112.784V3.20455H112.87C113.023 2.87216 113.256 2.60511 113.569 2.40341C113.881 2.19886 114.284 2.09659 114.779 2.09659C115.222 2.09659 115.61 2.1875 115.942 2.36932C116.275 2.5483 116.533 2.82102 116.718 3.1875C116.902 3.55114 116.995 4.01136 116.995 4.56818V8.72727H115.989V4.63636C115.989 4.12216 115.855 3.72159 115.588 3.43466C115.321 3.14489 114.955 3 114.489 3C114.168 3 113.881 3.0696 113.628 3.20881C113.378 3.34801 113.181 3.55114 113.036 3.81818C112.891 4.08523 112.819 4.40909 112.819 4.78977Z"
        fill="white"
      />
    </svg>
  );
};
